import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import checBoxInCricle from "../../../../../assets/images/Checkbox-cyan.png";
import checBoxInCricle2 from "../../../../../assets/images/Checkbox_in_Circle2.png";
import hiddenEye from "../../../../../assets/images/hiddeneye.png";
import Heading from "../components/Heading";
import InputField from "../components/InputField";
import DropDown from "../components/DropDown";
import SubHeading from "../components/SubHeading";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import * as AuthApi from "../../../../../api/auth.api";
import * as JobApi from "../../../../../api/job.api";
import { openNotificationWithIcon, setCookie } from "utils";
import {
  passwordRegExp,
  SECRET_KEY,
  reValidationObject,
  validationMessages,
  nameForValidationMessage,
  // genders
} from "../../../../../constants/index";
import { useLocation } from "react-router";
import { Spin } from "antd";
import { useFetchInvite } from "../../../../../api/invite.api";
import Loader from "../../../../../components/Loader";

const SignUp = ({
  setUserEmail,
  setUserId,
  userEmail,
  setToken,
  setMobileSignUpStepNumber,
  signUpFlowStepsObj,
  jobIdFromUrl,
  techIdFromUrl,
}) => {
  const location = useLocation();
  const [checkBoxLabel, setCheckBoxLabel] = useState(false);
  const [showPasswordValidator, setShowPasswordValidator] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let [passwordIsGreaterThanSixChars, setPasswordIsGreaterThanSixChars] =
    useState(false);
  let [passwordContainsLetter, setPasswordContainsLetter] = useState(false);
  let [passwordContainsSpecialChar, setPasswordContainsSpecialChar] =
    useState(false);
  let [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const customerTypeParam = queryParams.get("customer-type");
  const customerType = customerTypeParam !== null ? customerTypeParam : "live";
  const [isLoading, setIsLoading] = useState(false);
  let inviteCodeFromLocal = localStorage.getItem("inviteCode");
  const [inviteCode, setInviteCode] = useState(
    inviteCodeFromLocal ? inviteCodeFromLocal : "nothing"
  );
  const { data: inviteData } = useFetchInvite(
    inviteCodeFromLocal ? inviteCodeFromLocal : null
  );
  const [referUserEmail, setReferUserEmail] = useState();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    // gender: "",
    password: "",
  });
  // console.log('Email', userEmail);
  const [hidePassword, setHidePassword] = useState(false);
  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    setUserEmail(userEmail);
    const ssoUserDetails = sessionStorage.getItem("ssoUserDetails");
    const ssoSignupUser = sessionStorage.getItem("SSOSignupUser");
 
    if (ssoUserDetails || ssoSignupUser) {
      setHidePassword(true);
      autoFillUserDetails(ssoUserDetails || ssoSignupUser);
    } else if (!inviteCode === "nothing") {
      window.location.href = `customer/mobile-sign-up?jobId=${jobIdFromUrl}&page=InputEmail`;
    }
  }, []);

  const autoFillUserDetails = (userDetailsJSON) => {
    try {
      const userDetails = JSON.parse(userDetailsJSON);
  
      if (userDetails) {
        const { email = "", firstName = "", lastName = "" } = userDetails;
        setUserEmail(email);
        setUserInfo((prevState) => ({
          ...prevState,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        }));
      }
    } catch (error) {
      console.error("Error parsing user details:", error);
    }
  };

  useEffect(() => {
    if (inviteData) {
      setUserEmail(inviteData?.email);
      if (inviteData?.inviteCode) {
        setReferUserEmail(inviteData?.ownerEmail);
      }
    }
  }, [inviteData]);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));

    // Password validation.
    if (name === "password") {
      // Checking if password contains atleast 6 characters
      if (value.trim().length > 5) {
        setPasswordIsGreaterThanSixChars(true);
      } else {
        setPasswordIsGreaterThanSixChars(false);
      }

      // Checking if password contains a character
      if (/[a-zA-Z]/.test(value.trim())) {
        setPasswordContainsLetter(true);
      } else {
        setPasswordContainsLetter(false);
      }

      // Checking if password contains a number
      if (/[0-9]/.test(value.trim())) {
        setPasswordContainsNumber(true);
      } else {
        setPasswordContainsNumber(false);
      }

      // Checking if password contains special char
      if (passwordRegExp.test(value.trim())) {
        setPasswordContainsSpecialChar(true);
      } else {
        setPasswordContainsSpecialChar(false);
      }
    }
  };

  // const onGenderChange = (gender) => {
  //   setUserInfo((prevState) => ({
  //     ...prevState,
  //     "gender": gender.trim(),
  //   }));
  // };

  const regularExpressionTesting = (fieldName) => {
    if (!reValidationObject[fieldName].test(String(userInfo[fieldName]))) {
      setIsLoading(false);
      openNotificationWithIcon(
        "info",
        "info",
        `${validationMessages[fieldName]} ${nameForValidationMessage[fieldName]}`
      );
      return false;
    } else {
      return true;
    }
  };

  const submitForm = async (e) => {
    e.preventDefault(e);
    setIsLoading(true);
    // console.log("Form submitted");
    // Skip first name and last name validation when hidePassword is true
    if (!hidePassword) {
      const firstNameValidation = regularExpressionTesting("firstName");
      const lastNameValidation = regularExpressionTesting("lastName");

      if (!firstNameValidation || !lastNameValidation) return;

      // First name validation.
      if (userInfo.firstName === "") {
        openNotificationWithIcon(
          "info",
          "info",
          "Please enter your first name."
        );
        setIsLoading(false);
        return;
      }

      if (userInfo.firstName.indexOf(" ") >= 0) {
        openNotificationWithIcon(
          "info",
          "info",
          "First name should not contain any empty space(s)."
        );
        setIsLoading(false);
        return;
      }

      // Last name validation.
      if (userInfo.lastName === "") {
        openNotificationWithIcon(
          "info",
          "info",
          "Please enter your last name."
        );
        setIsLoading(false);
        return;
      }


      if (userInfo.lastName.indexOf(" ") >= 0) {
        openNotificationWithIcon(
          "info",
          "info",
          "Last name should not contain any empty space(s)."
        );
        setIsLoading(false);
        return;
      }

      // Gender validation.
      // if (userInfo.gender === "") {
      //   openNotificationWithIcon(
      //     "info",
      //     "info",
      //     "Please enter your gender."
      //   );
      //   setIsLoading(false);
      //   return;
      // }
    }
    if (hidePassword) {
      // If hidePassword is true, assume all validations pass
      passwordIsGreaterThanSixChars = true;
      passwordContainsLetter = true;
      passwordContainsNumber = true;
      passwordContainsSpecialChar = true;
    } else {
      // Perform password validations when hidePassword is false

      if (userInfo.password === "") {
        openNotificationWithIcon("info", "info", "Please enter a password.");
        setIsLoading(false);
        return;
      }

      // Additional conditions related to password (example)
      if (userInfo.password.includes(" ")) {
        openNotificationWithIcon(
          "info",
          "info",
          "Password must not contain any empty space(s)."
        );
        setIsLoading(false);
        return;
      }

    }
    
    if (
      passwordIsGreaterThanSixChars &&
      passwordContainsLetter &&
      passwordContainsNumber &&
      passwordContainsSpecialChar
    ) {
      let res = await AuthApi.register({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        firstName: userInfo.firstName.replace(/\s/g, ""),
        lastName: userInfo.lastName.replace(/\s/g, ""),
        // gender: userInfo.gender,
        email: userEmail,
        password: userInfo.password,
        confirm_password: userInfo.password,
        receiveEmails: checkBoxLabel,
        // phoneNumber: phoneNumber,
        language: "English",
        userType: "customer",
        status: "completed",
        inviteCode: inviteCode,
        // isBusinessTypeAccount: isBusinessTypeAccount,
        // businessName: businessName,
        customerType: customerType,
        referred_code: referUserEmail,
        provider: hidePassword ? "google" : "email",
        partner: localStorage.getItem("partner")? localStorage.getItem("partner") : "",
      });

      if (res.user) {
        localStorage.setItem(SECRET_KEY, res.token.accessToken);
        localStorage.removeItem("userEmail");
        sessionStorage.removeItem("ssoUserDetails");
        
        openNotificationWithIcon(
          "success",
          "Success",
          "User registered successfully!"
        );
        setToken(res.token.accessToken);
        setUserId(res.user.id);
        setCookie(res.user)
        if (jobIdFromUrl) {
          try {
            await JobApi.updateJob(jobIdFromUrl, {
              customer: res.user.customer.id,
            });
            // setMobileSignUpStepNumber(signUpFlowStepsObj["AddCard"])
            if (techIdFromUrl) {
              window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard&technicianId=${techIdFromUrl}`;
            } else {
              window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard`;
            }
          } catch (error) {
            console.error("error in job create login", error);
          }
        } else if (inviteCode && inviteCode != "nothing") {
          // setMobileSignUpStepNumber(signUpFlowStepsObj["PhoneNumber"])
          window.location.href = `mobile?page=PhoneNumber&userId=${res.user.id}`;
        } else {
          // setMobileSignUpStepNumber(signUpFlowStepsObj["AddCard"])
          if (jobIdFromUrl) {
            if (techIdFromUrl) {
              window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard&technicianId={techIdFromUrl}`;
            } else {
              window.location.href = `mobile?jobId=${jobIdFromUrl}&page=AddCard`;
            }
          } else {
            window.location.href = `mobile?page=AddCard&userId=${res.user.id}`;
          }
        }
      } else if (res.success === false && res.message) {
        setIsLoading(false);
        openNotificationWithIcon("error", "Error", res.message);
      }
    } else {
      setIsLoading(false);
      openNotificationWithIcon(
        "error",
        "Invalid Password",
        "Please check your password."
      );
    }
  };

  return (
    <>
      <div className="headingDiv pl-20-max-width-280 pr-20-max-width-280">
        <div className="d-flex flex-column heading-div">
          <Heading text={"Create Account"} />
          <SubHeading
            text={"Enter your info to access our fast, friendly tech help!"}
          />
        </div>
        <form onSubmit={(e) => submitForm(e)} className="w-100percent">
          <div className="create-account-main-container">
            <div className="create-account-main-email">
              <span className="create-account-main-text font-nova">
                {userEmail}
              </span>
            </div>
            <div className="input-name-div">
              <InputField
                inputType={"text"}
                placeHolder={"First Name"}
                inputDivClass={"inputDivClass-Firstname"}
                inputClass={"inputClass-Firstname"}
                onChange={onInputChange}
                value={userInfo.firstName}
                name={"firstName"}
              />
              <InputField
                inputType={"text"}
                placeHolder={"Last Name"}
                inputDivClass={"inputDivClass-Firstname"}
                inputClass={"inputClass-Firstname"}
                onChange={onInputChange}
                value={userInfo.lastName}
                name={"lastName"}
              />
            </div>

            {/* <DropDown
              inputDivClass={"inputDivClass-gender"}
              inputClass={"inputClass-gender"}
              defaultValue="Gender"
              dropDownOptions={genders}
              onChange={onGenderChange}
              value={userInfo.gender}
              name="gender"
            /> */}

            {!hidePassword && (
              <>
                <InputField
                  hiddenEye={hiddenEye}
                  showEye={true}
                  hiddenEyeStyle={"hidden-icon"}
                  inputType={showPassword ? "text" : "password"}
                  placeHolder={"Create Password"}
                  inputDivClass={"inputDivClass-password"}
                  inputClass={"inputClass-Firstname"}
                  value={userInfo.password}
                  onChange={onInputChange}
                  name={"password"}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  onClick={() => setShowPasswordValidator(true)}
                />

                {showPasswordValidator &&
                  (!passwordIsGreaterThanSixChars ||
                    !passwordContainsLetter ||
                    !passwordContainsSpecialChar ||
                    !passwordContainsNumber) && (
                    <div className="password-validation-container">
                      <div className="six-char">
                        <img
                          className="six-char-icon"
                          src={
                            passwordIsGreaterThanSixChars
                              ? checBoxInCricle
                              : checBoxInCricle2
                          }
                        />
                        <span className="six-char-text font-nova">
                          6 Charcters
                        </span>
                      </div>
                      <div className="letter-div">
                        <img
                          className="letter-icon"
                          src={
                            passwordContainsLetter
                              ? checBoxInCricle
                              : checBoxInCricle2
                          }
                        />
                        <span className="letter-text font-nova">Letter</span>
                      </div>
                      <div className="special-char-div">
                        <img
                          className="six-char-icon"
                          src={
                            passwordContainsSpecialChar
                              ? checBoxInCricle
                              : checBoxInCricle2
                          }
                        />
                        <span className="special-char-text font-nova">
                          Special Character
                        </span>
                      </div>
                      <div className="number-div">
                        <img
                          className="six-char-icon"
                          src={
                            passwordContainsNumber
                              ? checBoxInCricle
                              : checBoxInCricle2
                          }
                        />
                        <span className="number-div-text font-nova">
                          Number
                        </span>
                      </div>
                    </div>
                  )}
              </>
            )}

            <Button
              variant="contained"
              className="contained-signup-btn"
              onClick={submitForm}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spin className="spinner spinner-pos" />
              ) : (
                <span className="contained-signup-btn-text">Sign Up</span>
              )}
            </Button>
            <div className="signUp-check-box">
              <div className="signUp-check-box-inner gap-0-max-width-280">
                <Checkbox
                  className="signUp-check"
                  onClick={() => setCheckBoxLabel(!checkBoxLabel)}
                  checked={checkBoxLabel}
                />
                <IconButton
                  disableRipple
                  onClick={() => setCheckBoxLabel(!checkBoxLabel)}
                >
                  <span className="signUp-check-text">
                    Sign up for email and updates
                  </span>
                </IconButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignUp;
