import React from "react";
import { useUser } from "context/useContext";
import { VIP_VALUES } from "../../constants";

const NormalJobFeedback = ({ normalJobCost, isTechnicianSubmitFeedback, handleLinkClick }) => {
  const { user } = useUser();
console.log('normalJobCost ::',normalJobCost)
  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            {normalJobCost && (normalJobCost.promoCodeAppied || normalJobCost.isVipJob ) ? (
              <>
                <strike>
                  <span className="job-value totalAmount">
                    {" "}
                    $ {normalJobCost.totalCost}
                  </span>
                </strike>
                <span className="job-value">
                  {" "}
                  $ {normalJobCost.discountedCost}
                  {isTechnicianSubmitFeedback && (
                    <> <small> (Pending Review) </small> </>
                  )}
                </span>
              </>
            ) : (
              <>
                {" "}
                $ {normalJobCost && normalJobCost.totalCost}
                {isTechnicianSubmitFeedback && (
                  <> <small> (Pending Review) </small> </>
                )}
              </>
            )}
          </span>
        </td>
      </tr>
      {
      (
        user && user.customer && !user.customer.vipVote.voted &&
        ((normalJobCost && normalJobCost.promoCodeAppied) ? (normalJobCost.discountedCost > 0) : (normalJobCost.totalCost > 0)) && !user?.businessName
      ) ? (
        <>
          <tr>
            <th style={{ 
              color: "#01d4d5",
            }}> Save {VIP_VALUES.meetingOffer}%! </th>
            <td>
              <span className="job-value">
                {normalJobCost && normalJobCost.promoCodeAppied ? (
                  <>
                    Your job could be
                    <span className="job-value offer">
                      {" "}
                      ${(normalJobCost.discountedCost * 0.85).toFixed(2)}
                    </span>
                  </>
                ) : (
                  <>
                    Your job could be
                    <span className="job-value offer">
                      {" "}
                      ${(normalJobCost.totalCost * 0.85).toFixed(2)}
                    </span>
                  </>
                )}
                <br />
                {" "}
                <a onClick={handleLinkClick} className="vip-offer-link">
                  Click here now to save with our VIP subscription.
                </a>
              </span>
            </td>
          </tr>
        </>
      ) : null}
    </>
  );
};

export default NormalJobFeedback;
