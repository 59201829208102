import { VIP_VALUES } from "../../constants";
import { useUser } from "context/useContext";
import React from "react";

const SixMinFreeJobFeedback = ({
  sixMinFreeJobCost,
  isTechnicianSubmitFeedback,
  handleLinkClick
}) => {

  const { user } = useUser();

  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            <> $ {sixMinFreeJobCost && sixMinFreeJobCost.totalCost}</>
          </span>
        </td>
      </tr>
      <tr>
        <th>Amount to be Paid</th>
        <td>
          <span className="job-value">
            {sixMinFreeJobCost && sixMinFreeJobCost.promoCodeApplied ? (
              <>
                <strike>
                  <span className="job-value totalAmount">
                    {" "}
                    $ {sixMinFreeJobCost.amountToBePaid}
                  </span>
                </strike>
                <span className="job-value">
                  {" "}
                  $ {sixMinFreeJobCost.discountedCost}
                  {isTechnicianSubmitFeedback && (
                    <> <small> (Pending Review) </small> </>
                  )}
                </span>
              </>
            ) : (
              <>
                {" "}
                $ {sixMinFreeJobCost && sixMinFreeJobCost.amountToBePaid}
                {isTechnicianSubmitFeedback && (
                  <small> (Pending Review) </small>
                )}
              </>
            )}
          </span>
        </td>
      </tr>
      {(user && user.customer && !user.customer.vipVote.voted && sixMinFreeJobCost && sixMinFreeJobCost.amountToBePaid > 0 && !user?.businessName) ? (
        <>
          <tr>
          <th style={{ 
              color: "#01d4d5",
            }}> Save {VIP_VALUES.meetingOffer}%! </th>
            <td>
              <span className="job-value">
                {
                  sixMinFreeJobCost && sixMinFreeJobCost.promoCodeApplied ? (
                    <>
                      {" "}
                      Your job could be
                      <span className="job-value offer">
                        {" "}
                        $ {(sixMinFreeJobCost.discountedCost * 0.85).toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      Your job could be
                      <span className="job-value offer">
                        {" "}
                        $ {(sixMinFreeJobCost.amountToBePaid * 0.85).toFixed(2)}
                      </span>
                    </>
                  )}
                <br />
                {" "}
                <a onClick={handleLinkClick} className="vip-offer-link">
                  Click here now to save with our VIP subscription.
                </a>
              </span>
            </td>
          </tr>
        </>
      ) : null
      }
    </>
  );
};

export default SixMinFreeJobFeedback;
