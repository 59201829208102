import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import moment from "moment";
import { useTools } from "context/toolContext";
import { useJob } from "context/jobContext";
import * as JobApi from "../../../api/job.api";
import mixpanel from "mixpanel-browser";
import { Modal } from "antd";
import * as JobCycleApi from "../../../api/jobCycle.api";
import {
  JobTags,
  techWaitForCustomerStartMeeting,
} from "../../../constants/index";
import JobCancelFrom from "pages/Dashboard/components/jobCancelFrom";
import * as WebSocket from "../../../api/webSocket.api";
import { useSocket } from "context/socketContext";
import {
  checkJobValidations,
  GAevent,
  getNumberOfPendingJobs,
  get_or_set_cookie,
  handleStartCall,
  openNotificationWithIcon,
  PushUserDataToGtm,
  deleteParticipant
} from "utils";
import TechAlreadyInMeetModal from "components/TechAlreadyInMeetModal/TechAlreadyInMeetModal";
import { useServices } from "context/ServiceContext";
import FeedbackCompulsionModal from "pages/Technician/feedbackCompulsion";
import { useLocation } from "react-router";
import ScheduleJobCancelModal from "pages/Dashboard/components/ScheduleJobCancelModal";
import Countdown, { zeroPad } from "react-countdown";

const CountDown = ({ waitMeetingStartTimer, renderer }) =>
  useMemo(
    () => (
      <Countdown
        date={Date.now() + waitMeetingStartTimer}
        renderer={renderer}
        key={waitMeetingStartTimer}
      />
    ),
    [waitMeetingStartTimer]
  );

const TechnicianJobsActiveBtn = ({
  job,
  btnsToShowList,
  userType,
  user,
  decline_job_by_technician,
  pushToMeeting,
}) => {
  const {
    scheduleJobApplyBtn = false,
    scheduleJobDeclineBtn = false,
    cancelScheduleJobTechBtn = false,
    joinJobBtn = false,
    acceptBtn = false,
    addToCalendarDropdownBtn = false,
    startCallWithCustomerBtn = false,
  } = btnsToShowList;
  const duration = 2;
  const {
    setJobId,
    setTypeForDetails,
    setStepDeciderDashboard,
    setJobFlowStep,
    jobFlowsDescriptions,
    setIsDisableStartCallBtn,
    isDisableStartCallBtn
  } = useTools();
  const { fetchJob } = useJob();
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [alreadyInJob, setAlreadyInJob] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [FeedbackJobId, setFeedbackJobId] = useState("");
  const { socket } = useSocket();
  const location = useLocation();
  const { checkIfTwoTierJobAndExpertTech } = useServices();
  const [waitMeetingStartTimer, setWaitMeetingStartTimer] = useState(
    techWaitForCustomerStartMeeting
  );
  const [timesUp, setTimesUp] = useState(false);
  // const [isDisableStartCallBtn, setIsDisableStartCallBtn] = useState(true);

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem("techWaitMeetingStartTimer")
    );
    if (timesUp && job.status === "Accepted" && storedData && storedData.jobId && job.id === storedData.jobId) {
      setIsDisableStartCallBtn(false);
      openNotificationWithIcon(
        "info",
        "Customer did not start the meeting. You can initiate it now"
      );
    }
  }, [timesUp]);

  useEffect(() => {
    (async () => {
      const storedData = JSON.parse(
        localStorage.getItem("techWaitMeetingStartTimer")
      );
      if (storedData && storedData.jobId && job.id === storedData.jobId) {
        const now = new Date().getTime();
        const timeDiff = now - new Date(storedData.setWaitingTimer).getTime();
        setWaitMeetingStartTimer(techWaitForCustomerStartMeeting - timeDiff);
      }
    })();
  }, [job.id]);

  const openJobDetails = async (e) => {
    e.stopPropagation();
    const jobid = job?.id;
    await setJobId(jobid);
    await fetchJob(jobid);
    let jobResponse = await JobApi.retrieveJob(jobid);
    setTypeForDetails("details");
    const scheduleAccepted = jobResponse.schedule_accepted == false;
    const techDeclinedJob =
      jobResponse?.tech_declined_ids.includes(user?.technician?.id) == false;
    const isTech = user && user?.userType == "technician";
    if (scheduleAccepted && isTech && techDeclinedJob) {
      setTypeForDetails("apply");
    }
    if (userType === "technician") {
      mixpanel.identify(user.email);
      mixpanel.track(
        "Technician  - Click on Job details from dashboard page ",
        { JobId: jobid }
      );
    }
    setStepDeciderDashboard(6);
  };

  const applyJob = (e) => {
    e.currentTarget.disabled = true;
    e.stopPropagation();
    const jobid = job?.id;
    fetchJob(jobid);
    setJobId(jobid);
    setTypeForDetails("apply");
    setStepDeciderDashboard(6);
    if (user) {
      mixpanel.identify(user.email);
      mixpanel.track(`${user.userType}- Apply for this job from dashboard`, {
        JobId: jobid,
      });
    }
  };

  const handleScheduledDecline = async (e) => {
    const jobid = job?.id;
    let msg = "Are you sure you want to decline this job?";
    Modal.confirm({
      title: msg,
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      async onOk() {
        mixpanel.identify(user.email);
        mixpanel.track("Technician - Job declined from dashboard", {
          JobId: jobid,
        });
        await JobCycleApi.create(
          JobTags.TECH_DECLINED_SCHEDULE_JOB,
          jobid,
          user.id
        );
        postAgainJobDeclineByTech(jobid);
        decline_job_by_technician(jobid, false);
      },
    });
  };

  const postAgainJobDeclineByTech = async (jobid) => {
    try {
      // find job details
      let selectedJob = await JobApi.retrieveJob(jobid);
      let post_again_reference_technician =
        selectedJob.post_again_reference_technician;
      if (
        post_again_reference_technician &&
        post_again_reference_technician.length > 0
      ) {
        // let dataToUpdate = {
        //   status: "Declined",
        // };
        // await JobApi.updateJob(jobid, dataToUpdate);
        await deleteParticipant(jobid,user.id)
      }
    } catch (err) {
      openNotificationWithIcon("error", "Error", err.message);
    }
  };

  const handleScheduledCancel = (e) => {
    e.stopPropagation();
    setIsCancelModal(true);
  };

  const handleTechnicianCall = async (e) => {
    e.stopPropagation();
    e.currentTarget.disabled = true;
    let jobId = job?.id;
    handleStartCall(e, jobId, socket);
  };

  const handleAccepted = async (e) => {
    // This condition will check if technician already present in the meeting or not ? If  present then we don't allow to move further ~ Jagroop
    if (user && user?.userType == "technician") {
      const checkHaveAlreadyInJob = await getNumberOfPendingJobs(user);
      if (checkHaveAlreadyInJob) {
        setAlreadyInJob(true);
        return;
      }
    }
    // let job = job
    let jobId = job.id;
    // const res = await JobApi.retrieveJob(jobId);
    Modal.confirm({
      title: "Are you sure you want to accept this job?",
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      async onOk() {
        let resultVal = await checkIfTwoTierJobAndExpertTech(
          user?.technician,
          job
        );
        const check_feedback = await JobApi.checkLastJobFeedback({
          technician: user?.technician?.id,
        });
        await JobApi.updateJob(jobId, { acceptedJobTime: new Date() });
        if (resultVal == false) {
          openNotificationWithIcon(
            "error",
            "Error",
            "This job is only for experts.Please contact admin to make you one."
          );
          window.location.reload();
        } else if (check_feedback.job_id != undefined) {
          setShowFeedbackModal(true);
          setFeedbackJobId(check_feedback.job_id);
        } else {
          let validation = checkJobValidations(user, jobId, location);
          if (validation) {
            try {
              await JobApi.sendJobAcceptEmail(jobId);
            } catch (err) {
              console.log("this is error in dashboard.js:: ", err);
            }
          }
        }
      },
    });
  };

  const renderer = useMemo(
    () =>
      ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          setTimesUp(true);
        }
        return (
          <h1>
            {/* {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} */}
          </h1>
        );
      },
    []
  );

  return (
    <>
      <CountDown
        waitMeetingStartTimer={waitMeetingStartTimer}
        renderer={renderer}
      />
      <TechAlreadyInMeetModal
        setAlreadyInJob={setAlreadyInJob}
        alreadyInJob={alreadyInJob}
      />
      <ScheduleJobCancelModal
        isCancelModal={isCancelModal}
        setIsCancelModal={setIsCancelModal}
        cancelJobId={job?.id}
        user={user}
        type={userType == "customer" ? "Customer" : "Technician"}
        job={job}
        decline_job_by_technician={decline_job_by_technician}
        setcurrentStep={setStepDeciderDashboard}
      />
      {/* <JobCancelFrom
        isCancelModal={isCancelModal}
        setIsCancelModal={setIsCancelModal}
        cancelJobId={job?.id}
        user={user}
        type={userType == "customer" ? "Customer" : "Technician"}
        job={job}
        decline_job_by_technician={decline_job_by_technician}
        setcurrentStep={setStepDeciderDashboard}
      /> */}
      <Row key={job?.id}>
        <Col xs="12">
          <Button
            className="mb-2 btn app-btn"
            name={`${job?.id}`}
            title="Click to see job details."
            onClick={openJobDetails}
          >
            Details<span></span>
          </Button>
          {scheduleJobApplyBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={applyJob}
              name={`${job.id}`}
              title="Click to see job details and apply for this job."
            >
              Accept<span></span>
            </Button>
          )}
          {scheduleJobDeclineBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={handleScheduledDecline}
              name={job.id}
              title="You will no longer see this job if you click on this button."
            >
              Decline<span></span>
            </Button>
          )}
          {cancelScheduleJobTechBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={handleScheduledCancel}
              name={job.id}
              title="You will no longer see this job if you click on this button."
            >
              Cancel<span></span>
            </Button>
          )}
          {joinJobBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={pushToMeeting}
              name={job.id}
            >
              Join<span></span>
            </Button>
          )}
          {acceptBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={handleAccepted}
              name={job.id}
              title="Accept the current job"
            >
              Accept<span></span>
            </Button>
          )}
          {addToCalendarDropdownBtn && (
            <>
              <div className="addToCalendar-geeker mb-2">
                <AddToCalendarDropdown
                  event={{
                    title: "Geeker Job",
                    duration,
                    description: job?.issueDescription,
                    startDatetime: moment
                      .utc(job?.primarySchedule)
                      .format("YYYYMMDDTHHmmssZ"),
                    endDatetime: moment
                      .utc(
                        new Date(
                          new Date(job?.primarySchedule).setHours(
                            new Date(job?.primarySchedule).getHours() + 2
                          )
                        )
                      )
                      .format("YYYYMMDDTHHmmssZ"),
                  }}
                  buttonProps={{
                    className: "thisssssssssss",
                  }}
                  items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
                />
              </div>
            </>
          )}
          {startCallWithCustomerBtn && (
            <Button
              className="mb-2 btn app-btn"
              onClick={handleTechnicianCall}
              name={job?.id}
              title="Accept the current job"
            >
              Start call with customer<span></span>
            </Button>
          )}
        </Col>
      </Row>

      {showFeedbackModal && (
        <FeedbackCompulsionModal
          user={user}
          isModalOpen={showFeedbackModal}
          jobId={FeedbackJobId}
        />
      )}
    </>
  );
};

export default TechnicianJobsActiveBtn;
