import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Modal as AntdModal } from "antd";
import "./style.css";
import icon from "../../assets/icons/voteIcon.png";
import bronzeIcon from "../../assets/icons/bronzeIcon.png";
import silverIcon from "../../assets/icons/silverIcon.png";
import goldIcon from "../../assets/icons/goldIcon.png";
import closeIcon from "../../assets/icons/close.svg";
import { useUser } from "../../context/useContext";
import { VIP_VALUES, PLAN_ORDER } from "../../constants";
import { getStripeCustomerCardsInfo, retrieveCustomer, saveCustomerVipVote, updateCustomer } from "../../api/customers.api";
import { buySubscription, cancelSubscription, createSubscriptionHistory, getAllPlans } from "../../api/subscription.api";
import { useParams, useHistory } from "react-router-dom";
import { openNotificationWithIcon, isLiveUser, getCookie, PushUserDataToGtm, decryptStripeKey, getStripeObject } from "utils";
import { useAuth } from "context/authContext";
import moment from "moment";
import Axios from "axios";
import { useSocket } from "context/socketContext";
import AddCardForm from "pages/Customer/Profile/steps/addCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { useTools } from "../../context/toolContext";

let stripePromise = "";
export default function VoteBanner(props) {
  const [showBanner, setShowBanner] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showBusinessPopup, setShowBusinessPopup] = useState(false);
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [activeSubscription, setActiveSubscription] = useState({});
  const getMeasurementId = decryptStripeKey(
    process.env.REACT_APP_GA_MEASUREMENT_ID
  );
  const mes_id = getMeasurementId ? getMeasurementId.split("-")[1] : false;
  const { socket } = useSocket();
  const { user } = useUser();
  const { jobId } = useParams();
  const { refetch } = useAuth();
  const [promoId, setPromoId] = useState("");
  const [couponId, setCouponId] = useState("");
  let liveUser;
  const [showSpinner, setShowSpinner] = useState(false);
  const [plans, setPlans] = useState([]);
  const [starterPlanData, setStarterPlanData] = useState(null);
  const [businessPlanData, setBusinessPlanData] = useState(null);
  const [goldPlanData, setGoldPlanData] = useState(null);
  const [starterPlanInfo, setStarterPlanInfo] = useState([]);
  const [businessPlanInfo, setBusinessPlanInfo] = useState([]);
  const [goldPlanInfo, setGoldPlanInfo] = useState([]);
  const [cardInfoUpdated, setCardInfoUpdated] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const carouselItems = [
    "Geeker offers discounted subscription plans for businesses from 1-1,000 users? 🚀",
    "Geeker can help with 25+ software and IT categories (and adding more all the time). 💻",
    "Geeker is rolling out a VIP discount plan for our frequent users? 🥇",
    "Geeker gives you the option to chat and upload files with your technician? 🤓",
    "Geeker allows you to select a technician you used previously. 🤝",
    "Geeker is in its third year of business and has over 6,000 completed calls. 📈",
  ];
  const [currentItem, setCurrentItem] = useState(0);
  const [animateIn, setAnimateIn] = useState(true);
  const {
    
    
    setActiveMenu,
    setStepDeciderDashboard
  } = useTools();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimateIn(false);
      setTimeout(() => {
        setAnimateIn(true);
        setCurrentItem((prevItem) => (prevItem + 1) % carouselItems.length);
      }, 500);
    }, 6000);
    return () => clearInterval(interval);
  }, [carouselItems]);

  useEffect(() => {
    if (props.handleShowPopup) {
      setShowPopup(true);
    }
  }, [props.handleShowPopup]);


  useEffect(() => {
    (async () => {
      if (user) {
        liveUser = await isLiveUser(user);
        stripePromise = await getStripeObject(user);
        let allPlans = await getAllPlans({ liveUser: liveUser });
        setPlans(allPlans.data);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (user && plans && plans.length > 0) {
        if (
          user.customer &&
          user.customer.subscription &&
          user.customer.subscription.plan_id
        ) {
          let activePlan = plans.find(
            (o) => o.id === user.customer.subscription.plan_id
          );
          if (activePlan) {
            let activePlanData = { ...activePlan };
            activePlanData["total_amount"] =
              parseFloat(activePlanData.price.unit_amount / 100) -
              (
                0.05 * parseFloat(activePlanData.price.unit_amount / 100)
              ).toFixed(2);
            setActiveSubscription(activePlanData);
          } else {
            openNotificationWithIcon(
              "error",
              "Error",
              "Your active plan is no more available."
            );
          }
        }
      }

      if (user && plans && plans.length > 0) {
        for (let i = 0; i <= plans.length - 1; i++) {
          if (typeof plans[i]["metadata"]["key_features"] === "string") {
            plans[i]["metadata"]["key_features"] = JSON.parse(
              plans[i]["metadata"]["key_features"]
            );
          }
          if (
            typeof plans[i]["metadata"]["key_features_submenu"] === "string"
          ) {
            plans[i]["metadata"]["key_features_submenu"] = JSON.parse(
              plans[i]["metadata"]["key_features_submenu"]
            );
          }
        }
      }
    })();
  }, [user, plans, refetch]);

  useEffect(() => {
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      liveUser = await isLiveUser(user);
      let allPlans = await getAllPlans({ liveUser: liveUser });
      await allPlans.data.forEach((plan) => {
        if (plan.metadata.planOrder === PLAN_ORDER.Starter) {
          setStarterPlanInfo(
            plan.metadata.key_features
              .replace("[", "")
              .replace("]", "")
              .replaceAll(`"`, "")
              .split(",")
              .reverse()
          );
          setStarterPlanData(plan)
        }
        if (plan.metadata.planOrder === PLAN_ORDER.Business) {
          setBusinessPlanInfo(
            plan.metadata.key_features
              .replace("[", "")
              .replace("]", "")
              .replaceAll(`"`, "")
              .split(",")
              .reverse()
          );
          setBusinessPlanData(plan)
        }
        if (plan.metadata.planOrder === PLAN_ORDER.Gold) {
          setGoldPlanInfo(
            plan.metadata.key_features
              .replace("[", "")
              .replace("]", "")
              .replaceAll(`"`, "")
              .split(",")
              .reverse()
          );
          setGoldPlanData(plan)
        }
      });

      if (user && user.customer && !user.customer.vipVote.voted && !user.isBusinessTypeAccount) {
        setShowBanner(true);
      }
    })();
  }, [user]);

  const handleVoteClick = (value) => async () => {
    const customerId = user?.customer.id;

    const data = {
      value,
      job_id: jobId,
    };

    setShowPopup(false);
    setShowBanner(false);
    if (props.handleShowPopup) {
      props.setHandleShowPopup(false)
    }

    const response = await saveCustomerVipVote(customerId, data);

    if (response?.status === "Successful") {
      openNotificationWithIcon("success", "Vote Success", "Your vote has been recorded successfully.");
    } else {
      openNotificationWithIcon("error", "Vote Failed", "Failed to record your vote. Please try again.");
    }

  };

  const handleSubscribeClick = () => {
    setShowPopup(false);
    setShowBusinessPopup(true);
    if (props.handleShowPopup) {
      props.setHandleShowPopup(false)
    }
  };

  const handleBusinessSubscribeClick = (value) => async () => {
    setSelectedSubscription(value);
    setShowAreYouSurePopup(true);
    setShowBusinessPopup(false);
  };

  const handleBuySubscription = (planData) => {
    if (user?.roles.indexOf("owner") !== -1) {
      buyPlanInit(
        planData.id,
        planData.name,
        planData.price.id,
        planData.metadata.total_minutes,
        planData.metadata.discount
      );
    } else {
      setShowSpinner(false);
      setShowAreYouSurePopup(false);
      openNotificationWithIcon("error", "Subscription Error", "You are not authorized to buy subscription. Please contact your account manager.");
    }
  };

  const buyPlanInit = async (
    plan_id,
    plan_name,
    price_id,
    total_minutes,
    discount
  ) => {
    if (user) {
      if (
        user.customer &&
        user.customer.stripe_id &&
        user.customer.stripe_id !== ""
      ) {
        let cardsInfo = await getStripeCustomerCardsInfo({
          stripe_id: user.customer.stripe_id,
          liveUser: liveUser,
        });
        cardsInfo = cardsInfo.data ? cardsInfo.data : [];
        if (cardsInfo.length > 0) {
          if (
            user.customer.subscription &&
            user.customer.subscription.subscription_id
          ) {
            if (
              Object.keys(activeSubscription).length !== 0 &&
              activeSubscription.metadata.valid_for === "one_month"
            ) {
              let buyDate = new Date(
                user.customer.subscription.plan_purchased_date
              );
              let nowDate = new Date();
              let diffDays = getDifferenceInDays(buyDate, nowDate);
              if (diffDays > 30) {
                cancelOldAndBuyNewOne(
                  cardsInfo,
                  price_id,
                  plan_id,
                  plan_name,
                  total_minutes,
                  discount
                );
              } else {
                askModalConfimation(
                  cardsInfo,
                  price_id,
                  plan_id,
                  plan_name,
                  total_minutes,
                  discount
                );
              }
            }
          } else {
            let subscriptionHistory = user.customer.subscription_history;
            buyPlan(
              cardsInfo,
              price_id,
              plan_id,
              plan_name,
              total_minutes,
              discount,
              subscriptionHistory,
              promoId,
              couponId
            );
          }
        } else {
          setIsModalOpen(true);
          setShowSpinner(false);
          setShowAreYouSurePopup(false);
        }
      } else {
        setIsModalOpen(true);
        setShowSpinner(false);
        setShowAreYouSurePopup(false);
      }
    } else {
      openNotificationWithIcon(
        "info",
        "Info",
        "Looking like your session is expired. Please reload your page and try again."
      );
    }
  };

  const cancelOldAndBuyNewOne = async (
    cardsInfo,
    price_id,
    plan_id,
    plan_name,
    total_minutes,
    discount
  ) => {
    let subscriptionHistory = user.customer.subscription_history;
    const buySubscriptionResponse = await buyPlan(
      cardsInfo,
      price_id,
      plan_id,
      plan_name,
      total_minutes,
      discount,
      subscriptionHistory
    );
    liveUser = await isLiveUser(user);
    if (buySubscriptionResponse && buySubscriptionResponse.success) {
      let cancelDataToSend = {
        subscription_id: user.customer.subscription.subscription_id,
        liveUser: liveUser,
      };
      let cRes = await cancelSubscription(cancelDataToSend);
      if (cRes && cRes.success) {
        console.log("Old subscription cancelled successfully.");
        let oldPlanDetails = user.customer.subscription;
        oldPlanDetails["status"] = cRes.data.status;
        oldPlanDetails["plan_inactive_date"] = new Date();
        subscriptionHistory.push(oldPlanDetails);
        await createSubscriptionHistory({
          cust_id: user.customer.id,
          subscription_history: oldPlanDetails,
        });
        console.log("Old subscription history created successfully.");
      } else {
        openNotificationWithIcon(
          "error",
          "Error",
          "Failed to cancel old subscription and upgrade new subscription. Please reload your page and try again."
        );
      }
    }
  };

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }


  const askModalConfimation = (
    cardsInfo,
    price_id,
    plan_id,
    plan_name,
    total_minutes,
    discount
  ) => {
    setShowAreYouSurePopup(false);
    setShowSpinner(false);
    AntdModal.confirm({
      title:
        "Your previous subscription will get expired and we will add the remaining minutes to your new subsctiption. Are you sure you want to buy new subscription?",
      okText: "Yes",
      cancelText: "No",
      className: "app-confirm-modal",
      onOk() {
        setShowSpinner(true);
        cancelOldAndBuyNewOne(
          cardsInfo,
          price_id,
          plan_id,
          plan_name,
          total_minutes,
          discount
        );
      },
      onCancel() {
        setShowSpinner(false);
      },
    });
  };

  const buyPlan = async (
    cardsInfo,
    price_id,
    plan_id,
    plan_name,
    total_minutes,
    discount,
    subscriptionHistory = [],
    promoId = "",
    couponId = ""
  ) => {
    openNotificationWithIcon("info", "Info", "Buying subscription ....");
    let cardObj = cardsInfo.find((o) => o.default_card === "yes");
    let purchaseDate = moment().format("MM/DD/YYYY hh:mm a");
    let subscribeDataToSend = {
      customer_id: cardObj["customer"],
      price_id: price_id,
      product_id: plan_id,
      email: user.email,
      name: user.firstName + " " + user.lastName,
      liveUser: liveUser,
      plan_purchased_date: moment(purchaseDate).format("MM-DD-YYYY"),
    };
    let sRes = await buySubscription(subscribeDataToSend);

    if (sRes && sRes.success) {
      sendSubscriptionGTMTag(sRes);
      let planDetails = {};
      total_minutes = parseInt(total_minutes);
      planDetails["plan_id"] = plan_id;
      planDetails["plan_name"] = plan_name;
      planDetails["plan_purchased_date"] = new Date();
      planDetails["total_minutes"] = total_minutes;
      planDetails["total_seconds"] = total_minutes * 60;
      planDetails["time_used"] = 0;
      planDetails["invoice_id"] = sRes.data.latest_invoice;
      planDetails["subscription_id"] = sRes.data.id;
      planDetails["discount"] = discount;
      planDetails["status"] = sRes.data.status;
      planDetails["paidPrice"] = sRes.paidPrice;
      planDetails["priceOff"] = sRes.priceOff;

      if (user?.customer?.subscription) {
        if (user?.customer?.subscription?.time_from_previous_subscription) {
          planDetails["time_from_previous_subscription"] =
            user?.customer?.subscription?.time_from_previous_subscription +
            (user?.customer?.subscription?.total_seconds -
              user?.customer?.subscription?.time_used);
        } else {
          planDetails["time_from_previous_subscription"] =
            user?.customer?.subscription?.total_seconds -
            user?.customer?.subscription?.time_used;
        }
        if (user?.customer?.subscription?.time_from_previous_subscription) {
          planDetails["grand_total_seconds"] =
            user?.customer?.subscription?.time_from_previous_subscription +
            (user?.customer?.subscription?.total_seconds -
              user?.customer?.subscription?.time_used) +
            total_minutes * 60;
        } else {
          planDetails["grand_total_seconds"] =
            user?.customer?.subscription?.total_seconds -
            user?.customer?.subscription?.time_used +
            total_minutes * 60;
        }
      } else {
        planDetails["grand_total_seconds"] = total_minutes * 60;
      }

      if (subscriptionHistory && subscriptionHistory.length > 0) {
        await updateCustomer(user.customer.id, {
          subscription: planDetails,
        });
      } else {
        await updateCustomer(user.customer.id, {
          subscription: planDetails,
        });
      }

      sendSubscriptionGTMTag(sRes);

      openNotificationWithIcon("success", "Success", sRes.messageToDisplay);

      let activePlan = plans.find((o) => o.id === plan_id);

      let activePlanData = { ...activePlan };
      activePlanData["total_amount"] =
        parseFloat(activePlanData.price.unit_amount / 100) -
        (0.05 * parseFloat(activePlanData.price.unit_amount / 100)).toFixed(2);
      setActiveSubscription(activePlan);
      setPlans([...plans]);
      setShowSpinner(false);
      setShowAreYouSurePopup(false);
      return sRes;
    } else if (sRes && sRes.success == false) {
      openNotificationWithIcon("error", "Error", sRes.messageToDisplay);
      setShowSpinner(false);
      setShowAreYouSurePopup(false);
      return sRes;
    } else {
      openNotificationWithIcon(
        "error",
        "Error",
        "Something went wrong, please try again."
      );
      setShowSpinner(false);
      setShowAreYouSurePopup(false);
    }
    setPromoId("");
    setCouponId("");
  };

  const getIPData = async () => {
    try {
      const res = await Axios.get(" https://geolocation-db.com/json/");
      return res.data.IPv4;
    } catch (error) {
      return "";
    }
  };

  const sendSubscriptionGTMTag = async (buySubscriptionRes) => {
    try {
      const client_id = String(
        getCookie("_ga").split(".")[2] + "." + getCookie("_ga").split(".")[3]
      );
      const session_id = String(getCookie(`_ga_${mes_id}`).split(".")[2]);
      const facebook_fbp = String(getCookie("_fbp"));
      const facebook_fbc = String(getCookie("_fbc"));
      const ip = await getIPData();

      let dataToSend = {};
      dataToSend.tagName = "subscriptionStarted";
      dataToSend.client_id = client_id;
      dataToSend.eventObject = buySubscriptionRes;
      dataToSend.facebook_fbp = facebook_fbp;
      dataToSend.facebook_fbc = facebook_fbc;
      dataToSend.customer_id = user.customer.id;
      dataToSend.session_id = session_id;
      dataToSend.value = buySubscriptionRes.paidPrice;
      dataToSend.user_agent = navigator.userAgent;
      dataToSend.customer_ip = ip;
      dataToSend.customerUserInfo = user;

      const updatedCustomer = await retrieveCustomer(
        user.customer.id
      );

      if (updatedCustomer) {
        dataToSend.customerInfo = updatedCustomer;
      } else {
        dataToSend.customerInfo = user.customer;
      }
      socket.emit("send-GTM-data", { dataToSend });
      if (process.env.REACT_APP_URL) {
        const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
        PushUserDataToGtm(
          "subscription_started",
          user,
          appUrl,
          dataToSend.value
        );
      }
    } catch (error) {
      console.error("Some error occured in sendSubscriptionGTMTag", {
        error: error,
      });
    }
  };

  const handelVipMembership = async () => {
    if (window.location.pathname !== "/" && window.location.pathname !== "/dashboard") {
      history.push('/dashboard');
      
      setTimeout(() => {
        setActiveMenu('vip_membership');
        setStepDeciderDashboard(16);
      }, 100);
    } else {
      setActiveMenu('vip_membership');
      setStepDeciderDashboard(16);
    }
  };

  const handleBusinessSubscribe = (value) => async () => {
    console.log("stripePromise", stripePromise);
    const selectedPlan = plans.find((plan) => plan.metadata.planOrder === value);
    handleBuySubscription(selectedPlan);
    setShowSpinner(true);
  };

  const handelBannerConntent = () => {
    const isPartner = localStorage.getItem("partner") ? true : false;
    if (props.page && props.page === "HelpIsOnTheWay") {
      return (
        <>
          {user?.customer && !user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription?.plan_id && !isPartner && (
            <div className="vote-banner" style={{
              cursor: "default"
            }}>
              <div className={`vote-banner__content ${animateIn ? 'slide-in' : 'slide-out'}`}>
                <p className="vote-banner__content__text" style={{ fontWeight: "normal" }}><strong>Did you know?</strong> {carouselItems[currentItem]}</p>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          {user?.customer && !user?.businessName && user?.roles.includes("owner") && !user?.customer?.subscription?.plan_id && !isPartner && (
            <div className="vote-banner" onClick={handelVipMembership}>
              <div className="vote-banner__content">
                <img
                  src={icon}
                  alt="vote icon"
                  className="vote-banner__content__icon"
                />
                <p className="vote-banner__content__text">
                  Special offer: Join our VIP program now and save {VIP_VALUES.meetingOffer}% off 
                  {window.location.pathname === "/dashboard" ? " your next job " : " this job "}
                  <spam className="vote-banner__content__link">
                    UPGRADE NOW
                  </spam>
                </p>
              </div>
            </div>
          )}
        </>
      )
    }
  }

  return (
    <>
      {handelBannerConntent()}
      {/* Popup */}
      <Modal show={showPopup} onHide={() => {
        setShowPopup(false)
        if (props.handleShowPopup) {
          props.setHandleShowPopup(false)
        }
      }}>
        <Modal.Header>
          <Modal.Title className="popup__top__text">Coming Soon!</Modal.Title>
          <span className="popup__close" onClick={() => {
            setShowPopup(false)
            if (props.handleShowPopup) {
              props.setHandleShowPopup(false)
            }
          }}>
            <img src={closeIcon} alt="close icon" />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="popup__content">
            <div className="popup__content__title">
              <img
                src={icon}
                alt="vote icon"
                className="popup__content__icon"
              />
              <p className="popup__content__text__title">
                Enjoy free minutes + recordings with VIP
              </p>
              <p className="popup__content__text__subtitle">
                Geeker VIP subscriptions are coming! Vote for your favorite package now, and we'll give you <strong>{VIP_VALUES.voteOffer}% off a job</strong> when you upgrade on launch day! 🚀
              </p>
            </div>

            {/* Pricing table content */}
            <div className="pricing-table">
              <div className="pricing-table__item">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={bronzeIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">Bronze</div>
                  </div>
                  <div className="pricing-table__item__price">${VIP_VALUES.bronzePrice} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.bronzeDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('bronze')} className="pricing-table__item__button">
                  Vote for Bronze
                </Button>
              </div>
              <div className="pricing-table__item recommended">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={silverIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">Silver</div>
                  </div>
                  <div className="pricing-table__item__price">${VIP_VALUES.silverPrice} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.silverDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('silver')} className="pricing-table__item__button">
                  Vote for Silver
                </Button>
              </div>
              <div className="pricing-table__item">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={goldIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">Gold</div>
                  </div>
                  <div className="pricing-table__item__price">${VIP_VALUES.goldPrice} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  <li><strong>{VIP_VALUES.goldDiscount}% off all</strong> Geeker services</li>
                  <li>+Free meeting recordings</li>
                </ul>
                <Button onClick={handleVoteClick('gold')} className="pricing-table__item__button">
                  Vote for Gold
                </Button>
              </div>
            </div>

            {/* No Thank you link */}
            <a onClick={() => {
              setShowPopup(false)
              if (props.handleShowPopup) {
                props.setHandleShowPopup(false)
              }
            }} className="popup__content__link thanks_you">
              No Thank You
            </a>
            {!user?.customer?.subscription &&
              <a onClick={handleSubscribeClick} className="popup__content__link business_subscriptions">
                Have employees? See business subscriptions built just for you!
              </a>
            }

            <div className="popup__content__disclaimer">
              *You will <strong>not</strong> be charged for signing up to receive notifications. You'll be notified when your subscription becomes available for purchase. <strong>Check your email for instructions.</strong>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Bisiness Subscription Popup */}
      <Modal show={showBusinessPopup} onHide={() => setShowBusinessPopup(false)}>
        <Modal.Header>
          <Modal.Title className="popup__top__text">One time offer</Modal.Title>
          <span className="popup__close" onClick={() => setShowBusinessPopup(false)}>
            <img src={closeIcon} alt="close icon" />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="popup__content">
            <div className="popup__content__title">
              <img
                src={icon}
                alt="vote icon"
                className="popup__content__icon"
              />
              <p className="popup__content__text__title">
                Support your whole business with Geeker
              </p>
              <p className="popup__content__text__subtitle">
                Subscribe to a business plan and get 2 weeks free!
              </p>
            </div>

            {/* Pricing table content */}
            <div className="pricing-table">
              <div className="pricing-table__item">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={bronzeIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">{starterPlanData?.name}</div>
                  </div>
                  <div className="pricing-table__item__price">${starterPlanData?.price?.unit_amount / 100} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  {
                    starterPlanInfo.map((feature, index) => (
                      index === 0 ?
                        <li key={index}><strong>{feature}</strong></li> :
                        <li key={index}>{feature}</li>
                    ))
                  }
                </ul>
                <div>
                  <Button onClick={handleBusinessSubscribeClick("1")} className="pricing-table__item__button">
                    Try {starterPlanData?.name}
                  </Button>
                  <div className="pricing-table__30days">First 2 weeks free!</div>
                </div>
              </div>
              <div className="pricing-table__item recommended">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={silverIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">{businessPlanData?.name}</div>
                  </div>
                  <div className="pricing-table__item__price">${businessPlanData?.price?.unit_amount / 100} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  {
                    businessPlanInfo.map((feature, index) => (
                      index === 0 ?
                        <li key={index}><strong>{feature}</strong></li> :
                        <li key={index}>{feature}</li>
                    ))
                  }
                </ul>
                <div>
                  <Button onClick={handleBusinessSubscribeClick("2")} className="pricing-table__item__button">
                    Try {businessPlanData?.name}
                  </Button>
                  <div className="pricing-table__30days">First 2 weeks free!</div>
                </div>
              </div>
              <div className="pricing-table__item">
                <div>
                  <div className="pricing-table__item__title">
                    <img
                      src={goldIcon}
                      alt="vote icon"
                      className="pricing-table__item__icon"
                    />
                    <div className="pricing-table__item__header">{goldPlanData?.name}</div>
                  </div>
                  <div className="pricing-table__item__price">${goldPlanData?.price?.unit_amount / 100} Month</div>
                </div>
                <ul className="pricing-table__item__features">
                  {
                    goldPlanInfo.map((feature, index) => (
                      index === 0 ?
                        <li key={index}><strong>{feature}</strong></li> :
                        <li key={index}>{feature}</li>
                    ))
                  }
                </ul>
                <div>
                  <Button onClick={handleBusinessSubscribeClick("3")} className="pricing-table__item__button">
                    Try {goldPlanData?.name}
                  </Button>
                  <div className="pricing-table__30days">First 2 weeks free!</div>
                </div>
              </div>
            </div>

            {/* No Thank you link */}
            <a onClick={() => setShowBusinessPopup(false)} className="popup__content__link thanks_you">
              No Thank You
            </a>

            <div className="popup__content__disclaimer">
              *Your first month is free. After 30 days, your service will continue at the standard rate of the plan you selected. You may cancel any time.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Are you sure Popup */}
      <Modal show={showAreYouSurePopup} onHide={() => setShowAreYouSurePopup(false)}>
        <Modal.Header>
          <Modal.Title className="popup__top__text">Are you sure?</Modal.Title>
          <span className="popup__close" onClick={() => setShowAreYouSurePopup(false)}>
            <img src={closeIcon} alt="close icon" />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="popup__content">
            <p className="popup__content__text__title__sure">
              You will be charged and subscribed to this plan?
            </p>
            <div className="yes_no_btons">
              {showSpinner ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Button onClick={handleBusinessSubscribe(selectedSubscription)} className="pricing-table__item__button yes"> Yes </Button>
              )}
              <Button onClick={() => {
                setShowAreYouSurePopup(false)
                setShowBusinessPopup(false)
              }} className="pricing-table__item__button no">
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {stripePromise !== "" && (
        <Elements stripe={stripePromise}>
          <AddCardForm
            user={user}
            cardsInfo={cardInfoUpdated}
            setCardsInfo={setCardInfoUpdated}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            source={"subscription"}
          />
        </Elements>
      )}
    </>
  );
}
