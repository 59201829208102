import React, { useEffect, useState, useMemo } from "react";
import jsonAnimation from "../../../../assets/animations/animation.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import KeepSearchingModal from "../Components/KeepSearchingModal";
import ScheduleForLaterModal from "../Components/ScheduleForLaterModal";
import { useSocket } from "../../../../context/socketContext";
import { useTools } from "../../../../context/toolContext";
import mixpanel from "mixpanel-browser";
import Countdown, { zeroPad } from "react-countdown";
import * as UserApi from "api/users.api";
import { useHistory, useLocation } from "react-router";
import CancelJobConfirmationModal from "../Components/CancelJobConfirmationModal";
import { useJob } from "../../../../context/jobContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import {
  calculateTimeDifference,
  defaultContactNumber,
} from "constants/expandableJobContants";
import {
  openNotificationWithIcon,
  checkCustomerHaveSubscriptionMinutes,
  PushUserDataToGtm,
  getCookie,
  checkPendingStatusOfSchedule,
  fetchChatOrCreate,
} from "../../../../utils/index";
import * as CustomerApi from "../../../../api/customers.api";
import * as JobNotificationHistory from "../../../../api/jobNotificationHistory.api";
import AfterBusinessHrsPopUpModal from "../Components/AfterBusinessHrsPopUpModal";
import styled from "styled-components";
import Badge from "@mui/material/Badge";
import * as JobApi from "../../../../api/job.api";
import notifySound from "../../../../assets/sounds/notification.mp3";
import ChatPanelTwilio from "components/ChatPanelTwilio";
import * as TwilioApi from "../../../../api/twilioChat.api";
import * as JobCycleApi from "../../../../api/jobCycle.api";
import { JobTags } from "../../../../constants";
import "../../../../style.css";
import HeadingWithLogo from "../Components/HeadingWithLogo";
import { ReactSVG } from "react-svg";
import SCREEN from "../../../../assets/images/SCREEN.svg";
import MSG from "../../../../assets/images/MSG.svg";
import PHONE from "../../../../assets/images/PHONE.svg";
import CROSS from "../../../../assets/images/CROSS.svg";
import innerScreen from "../../../../assets/images/Isolation_Mode.svg";
import CALANDER from "../../../../assets/images/calendar.svg";
import YourJobLabel from "../Components/YourJobLabel";
import VoteBanner from "components/VoteBanner";

const CountDown = ({ useTimer, renderer, timesUp }) =>
  useMemo(
    () => (
      <Countdown
        date={Date.now() + useTimer}
        renderer={renderer}
        key={useTimer}
        onComplete={timesUp}
      />
    ),
    [useTimer]
  );

const HelpIsOnTheWay = ({
  user,
  job,
  jobInfo,
  scheduleJobTime,
  setScheduleJobTime,
  selectedTechnician,
}) => {
  const [showKeepSearchingModal, setShowKeepSearchingModal] = useState(false);
  const [showScheduleForLaterModal, setShowScheduleForLaterModal] =
    useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [keepSearchingFor, setKeepSearchingFor] = useState("1 hours");
  const { useTimer, setUseTimer } = useTools();
  const { socket } = useSocket();
  const location = useLocation();
  const [isHovering, setIsHovering] = useState(false);
  const [isLimitExceed, setIsLimitExceed] = useState(false);
  const { fetchJob, updateJob } = useJob();
  const queryParams = new URLSearchParams(location.search);
  const technicianId = queryParams.get("technicianId")
    ? queryParams.get("technicianId")
    : selectedTechnician.value
      ? selectedTechnician.value
      : false;
  const transferredJob = queryParams.get("transferredJob")
    ? queryParams.get("transferredJob")
    : false;
  const jobIdParams = queryParams.get("jobId");
  const history = useHistory();
  const [newJobAlertCalled, setNewJobAlertCalled] = useState(false);
  const [searchTimesUp, setSearchTimesUp] = useState(false);
  const [sameTechIdAvailable, setSameTechIdAvailable] = useState(false);
  const [sameTechIdAvailableSched, setSameTechIdAvailableSched] =
    useState(false);
  const [showRendererMint, setShowRendererMint] = useState("");
  const [showAfterBusinessHrs, setShowAfterBusinessHrs] = useState(false);
  const [chatPanelHasOpen, setChatPanelHasOpen] = useState(true);
  const [socketHits, setSocketHits] = useState(0);
  const [buttonKeyForChatPanel, setButtonKeyForChatPanel] = useState(false);
  const [showModalFooterOffPeak, setShowModalFooterOffPeak] = useState(false);
  const [showChatPanelForKeepSearching, setShowChatPanelForKeepSearching] = useState(false)
  const [isKeepSearching,setIsKeepSearching] = useState(false)
  // const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [scheduleforlater,setScheduleforlater]=useState(false)

  let audio = new Audio(notifySound);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let pendingJobs = await checkPendingStatusOfSchedule(user);
        console.log("pendingJobs.success", pendingJobs.success);
        if (pendingJobs.success) {
          setScheduleforlater(true);
          setIsLimitExceed(true);
        }
      } catch (error) {
        // Handle any errors from the async operation
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData(); // Call the async function
  
  }, []); 
  useEffect(() => {
    let tokenFromCookie = getCookie("user_access");
    if (tokenFromCookie) {
      console.log(
        "Looking for tokenFromCookie from Help On Its Way - found",
        {token : "..."+tokenFromCookie.substring(tokenFromCookie.length - 10)}
      );
    } else {
      console.log(
        "Looking for tokenFromCookie from Help On Its Way - not found"
      );
    }
    if (localStorage.getItem("state")) {
      localStorage.removeItem("state");
    }

    if (localStorage.getItem("postAgainJobModal")) {
      setSameTechIdAvailableSched(true);
    }
    (async () => {
      localStorage.removeItem("authorizationInfo");
      if (jobIdParams && job === undefined) {
        if (process.env.REACT_APP_URL) {
          const appUrl = process.env?.REACT_APP_URL?.split("/")[2] || false;
          PushUserDataToGtm("job_posted", user, appUrl);
        }
        // console.log("jobIdParam in profile setup ::", jobIdParams);
        await fetchJob(jobIdParams);
        setNewJobAlertCalled(true);
      }
    })();
  }, []);

  // setTimeout(() => {
  //   setIsButtonDisable(false);
  // }, 4000);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      backgroundColor: "red",
      padding: "0 4px",
    },
  }));

  const handelShowChat = () => {
    setButtonKeyForChatPanel(true);
    setChatPanelHasOpen(false);
    setSocketHits(0);
  };

  const handelShowChatForKeepSearching = () => {
    setSocketHits(0)
    console.log('handelShowChatForKeepSearching')
    setShowChatPanelForKeepSearching(true)
    setChatPanelHasOpen(false)
}

  useEffect(() => {
    if (job && job?.post_again_reference_technician) {
      setSameTechIdAvailable(true);
    }

    if (job && job?.keepSearching) {
      setIsKeepSearching(true);
    }

    if (
      job?.status == "Completed" ||
      job?.status == "Inprogress" ||
      job?.status == "Accepted"
    ) {
      window.location.href = "/dashboard";
    }
  }, [job]);

  useEffect(() => {
    const handleJobTransfer = async () => {
      if (transferredJob) {
        try {
          const updateJob = await JobApi.latestJobForCustomerTransferCase({ "customer": user?.customer?.id });
          if (updateJob && updateJob.length > 0) {
            await JobApi.updateJob(updateJob[0]?.id, { transfer_on_dashboard: false });
          }
          await transferJobDataChat(job, user);
        } catch (error) {
          console.error("An error occurred while handling the job transfer:", error);
        }
      }
    };
  
    handleJobTransfer();
  }, [transferredJob]);

  // useEffect(async () => {
  //   if (transferredJob) {
  //     // console.log("My console for newTransferJob", res);
  //     const updateJob = await JobApi.latestJobForCustomerTransferCase({ "customer": user?.customer?.id });
  //     await JobApi.updateJob(updateJob[0]?.id, { transfer_on_dashboard: false })
  //     await transferJobDataChat(job, user);
  //   }

  // }, [transferredJob]);

  const transferJobDataChat = async (res, user) => {
    try {
      await fetchChatOrCreate(res, user);
      return true;
    } catch (error) {
      console.error("An error occurred:", error);
      return false;
    }
  };
  
  const totalChatCount = () => {
    const jobChatDetail = window.localStorage.getItem("pendingJobHaveChat");
    let dataToSave = {
      jobId: jobIdParams,
      count: socketHits + 1,
    };
    if (jobChatDetail) {
      const responseData = JSON.parse(jobChatDetail);
      dataToSave["count"] = responseData["count"] + 1;
    }
    window.localStorage.setItem(
      "pendingJobHaveChat",
      JSON.stringify(dataToSave)
    );
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
   setIsHovering(false);
  };
  useEffect(() => {
      socket.on("open-chat-panel-talkjs-for-customer", (data) => {
          if (data.jobId === jobIdParams) {
              totalChatCount()
              setSocketHits((prevHits) => prevHits + 1);
              audio.play()
          }
      })

      socket.on('decline-post-again', async (data) => {
          if (data === jobIdParams) {
              openNotificationWithIcon('error', 'Error', "Previous geek you are trying to reach declined your job.")
              await updateJob(jobIdParams, { tech_search_time: 0, post_again_reference_technician: '' })
              await TwilioApi.updateTwilioConversation(job?.twilio_chat_service?.sid)
              setSameTechIdAvailable(false)
              setShowKeepSearchingModal(true)
              setSameTechIdAvailableSched(true)
              localStorage.setItem('postAgainJobModal', true)
          }
      });
      socket.on("open-chat-panel-talkjs", async (data) => {
          console.log("open-chat-panel-talkjs", data)
      })

  }, [socket])

  

  useEffect(() => {
    (async () => {
      if (job && newJobAlertCalled) {
        // console.log("Job-------------", job);
        if (
          job &&
          user &&
          user.customer &&
          job.customer.id !== user.customer.id
        ) {
          // console.log("window.location.href from helponway", user.customer.id);
          window.location.href = `/dashboard`;
          window.localStorage.setItem("CurrentStep", 7);
        } else {
          if (job && user) {
            // console.log("Inside useEffect if", job.customer.subscription);
            socket.emit("join", job.id);
            if (
              (job.id && job.cardPreAuthorization) ||
              job.customer.subscription
            ) {
              newJobAlert(job, user, technicianId);
            }
            // This condition will check in case of transfer case that if customer or it's owner have subscription minutes , if so then we will not hold any payment
            else if (job?.customer?.subscription || user?.ownerId) {
              const checkHaveSubscription =
                await checkCustomerHaveSubscriptionMinutes(job?.customer);
              if (!checkHaveSubscription) {
                await updateJob(job.id, {
                  cardPreAuthorization: true,
                  payment_type: "subscription_only",
                });
                newJobAlert(job, user, technicianId);
              } else {
                // Firstly it will hold the amount from customer if it is holded succedfully then it will redirect to tech search page
                const holdChargeResponse =
                  await checkAuthorizationByHoldingAmount();
                console.log("holdChargeResponse 2: ", holdChargeResponse);

                if (holdChargeResponse?.status === "Successful") {
                  await updateJob(job.id, { cardPreAuthorization: true });
                  newJobAlert(job, user, technicianId);
                } else {
                  const holdChargeResponse =
                    await checkAuthorizationByHoldingAmount();
                  if (holdChargeResponse?.status === "Successful") {
                    await updateJob(job.id, { cardPreAuthorization: true });
                    newJobAlert(job, user, technicianId);
                    
                  } else {
                    // This will toast out error and redirect to dashboard
                    openNotificationWithIcon(
                      "error",
                      "Error",
                      holdChargeResponse?.message
                    );
                    setTimeout(() => {
                      window.location.href = "/dashboard";
                      window.localStorage.setItem("CurrentStep", 7);
                    }, 2000);
                  }
                }
              }
            } else {
              // Firstly it will hold the amount from customer if it is holded succedfully then it will redirect to tech search page
              const holdChargeResponse =
                await checkAuthorizationByHoldingAmount();
              console.log("holdChargeResponse 1 : ", holdChargeResponse);

              if (holdChargeResponse?.status === "Successful") {
                await updateJob(job.id, { cardPreAuthorization: true });
            
                newJobAlert(job, user, technicianId);

              } else {
                const holdChargeResponse =
                  await checkAuthorizationByHoldingAmount();
                // console.log("holdChargeResponse 2: ", holdChargeResponse);

                if (holdChargeResponse?.status === "Successful") {
                  await updateJob(job.id, { cardPreAuthorization: true });
               
                  newJobAlert(job, user, technicianId);

                } else {
                  // This will toast out error and redirect to dashboard
                  await updateJob(job.id, { status: 'Draft' });
                  openNotificationWithIcon(
                    "error",
                    "Error",
                    holdChargeResponse?.message
                  );
                  setTimeout(() => {
                    window.location.href = "/dashboard";
                    window.localStorage.setItem("CurrentStep", 7);
                  }, 2000);
                }
              }
            }
          }
        }
      }
    })();
  }, [newJobAlertCalled]);

  const checkOwnerStripeId = async (user) => {
    if (user && user?.ownerId) {
      const ownerUserDetails = await UserApi.getUserById(user?.ownerId);
      // console.log("ownerUserDetails ::::", ownerUserDetails);
      if (ownerUserDetails?.customer?.stripe_id) {
        const ownerStripeId = ownerUserDetails?.customer?.stripe_id;
        return ownerStripeId;
      } else {
        openNotificationWithIcon(
          "info",
          "Info",
          `Please contact your owner to add card to proceed the current job!`
        );
        history.push("/");
        return;
      }
    }
  };

  // This function hold the $100 amount from customer and if it is holded succedfully then it will return Successful status otherwise Not Successful
  const checkAuthorizationByHoldingAmount = async () => {
    const ownerStripeStatus = await checkOwnerStripeId(user);
    const stripeId = user?.ownerId
      ? ownerStripeStatus
      : user?.customer?.stripe_id;
    const holdChargeResponse = await CustomerApi.holdChargeFromCustomer({
      stripe_id: stripeId,
      liveUser:
        user?.customer?.customerType && user?.customer?.customerType === "live"
          ? true
          : false,
      jobId: job?.id,
    });
    return holdChargeResponse;
  };

  const newJobAlert = async (jobInfo, userInfo, technicianId = false) => {
    mixpanel.identify(userInfo?.email);
    mixpanel.track("Customer - keep Searching technician ");
    mixpanel.people.set({
      $first_name: userInfo?.firstName,
      $last_name: userInfo?.lastName,
    });
    if (jobInfo.status === "Pending" || jobInfo.status === "Waiting") {
      const notifiedTechs =
        await JobNotificationHistory.getJobNotificationHistory(jobIdParams);
      // console.log("notified techs", notifiedTechs);
      if (notifiedTechs?.totalCount === 0) {
        await JobCycleApi.create(JobTags.HOLD_CHARGE, jobIdParams);
        await JobCycleApi.create(JobTags.FINDTECH, jobIdParams);
        // console.log(
        //   "No notifiedtech available, so going to call search-for-tech socket"
        // );
        socket.emit("search-for-tech", {
          jobData: jobInfo,
          searchSameTech: job.post_again_reference_technician ? true : false,
          technicianId: job.post_again_reference_technician
            ? job.post_again_reference_technician
            : false,
        });
      }
    } else if (jobInfo.status === "Scheduled") {
      // console.log("Nothing to do for schedule job here");
    } else {
      // console.log("window.location.href from helponway", jobInfo);
      window.location.href = "/";
    }
    socket.on("meeting:join-button", (props) => {
      window.location.href = `/customer/accept-job/${props.res}`;
    });
  };

  useEffect(() => {
    if (job) {
      const timeDiff = calculateTimeDifference(
        job.tech_search_start_at,
        job.notifiedTechs,
        job.tech_search_time
      );
      setUseTimer(timeDiff);
    }
  }, [job]);

  useEffect(() => {
    if (job && job?.post_again_reference_technician && showKeepSearchingModal) {
      openNotificationWithIcon(
        "info",
        "Info",
        "Your selected tech is busy somewhere please continue search for other techs."
      );
    }
  }, [showKeepSearchingModal]);

  useEffect(() => {
    if (job) {
      if (
        job?.post_again_reference_technician &&
        showRendererMint < 4 &&
        showRendererMint > 0
      ) {
        checkGeekerAvailabilityTime(job);
      } else {
        if (showRendererMint < 13 && showRendererMint > 4) {
          checkGeekerAvailabilityTime(job);
        }
      }
    }
  }, [showRendererMint]);

  const handleScheduleForLaterClick = () => {
    localStorage.removeItem("postAgainJobModal");
    setShowScheduleForLaterModal(true);
  };

  const handleCancelBtn = () => {
    setShowCancelJobModal(true);
  };

  /**
   * Checking Geeker Availability of time
   * @params = ''
   * @response : Will check if the job post time is between 9pm to 9am(EDT) and days are Saturday & Sunday then returns boolean value.
   * @author : Mritunjay
   */

  function checkGeekerAvailabilityTime(job) {
    const nonWorkingDays = ["Sat", "Sun"];
    const usTime = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    const usDay = new Date().toLocaleString("en-US", {
      timeZone: "America/New_York",
      weekday: "short",
    });
    const workingHours = usTime.getHours();
    console.log(
      "workingHours :::: >>>>>>" 
    );
    if (
      workingHours >= 21 ||
      workingHours < 9 ||
      nonWorkingDays.includes(usDay)
    ) {
      const storedValue = localStorage.getItem("showAfterBusinessHrs");
      if (storedValue) {
        setShowModalFooterOffPeak(storedValue === "true");
      } else {
        setShowModalFooterOffPeak(true);
      }
      // mixpanel code
      mixpanel.identify(user?.email);
      mixpanel.track("Customer - Before or after hours job", {
        usTime: usTime,
        issue: job.id,
      });
      mixpanel.people.set({
        $first_name: user?.firstName,
        $last_name: user?.lastName,
      });
    }

    if (workingHours >= 9 && workingHours < 21) {
      const storedValue = localStorage.getItem("showAfterBusinessHrs");
      if (storedValue) {
        setShowAfterBusinessHrs(storedValue === "true");
      } else {
        setShowAfterBusinessHrs(true);
      }
    }
  }
  const handleSocketEmission = async() => {
    let jobDetail = await JobApi.retrieveJob(jobIdParams)
    console.log('handleSocketEmission',jobDetail);
    socket.emit("Direct-Request-Sms-Notification", jobDetail);
  };
  const renderer = useMemo(
    () =>
      ({ hours, minutes, seconds }) => {
       
        if ((minutes == 1 || minutes == 3) && seconds === 0) {
        console.log("countdown inside ", minutes,seconds);
        handleSocketEmission();
          
        }
        setShowRendererMint(minutes);
        return (
          <span>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        );
      },
    []
  );

  const timesUp = async () => {
    JobApi.retrieveJob(job?.id).then((job) => {
      TwilioApi.updateTwilioConversation(job?.twilio_chat_service?.sid).then(
        () => {
          setShowKeepSearchingModal(true);
          setShowScheduleForLaterModal(false);
          setSearchTimesUp(true);
        }
      );
    });
  };

  const showChatPanelInTimerPage = () => {
    try {
      if (
        job &&
        job?.post_again &&
        job?.post_again_reference_job &&
        (job?.post_again_reference_technician || job?.tech_search_time > 900000)
      ) {
        return true;
      } else if (socketHits > 0) {
        return true;
      }

      let haveMessages = window.localStorage.getItem("pendingJobHaveChat");
      haveMessages = JSON.parse(haveMessages);
      if (
        haveMessages &&
        haveMessages.jobId &&
        haveMessages.jobId == jobIdParams
      ) {
        if (haveMessages.count) {
          setSocketHits(haveMessages.count);
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("error while checking unread messages in pending job", error);
      return false;
    }
  };
  const onClickGoToDashbord = async () => {
    // const updateJob = await JobApi.latestJobForCustomerTransferCase({ "customer": user?.customer?.id });
    // await JobApi.updateJob(updateJob[0].id, { transfer_on_dashboard: false })
    history.push("/");
  };

  return (
    <>
      <VoteBanner page={"HelpIsOnTheWay"} />
      <div
        className={`help-on-its-way-back-div ${(
          buttonKeyForChatPanel || showChatPanelForKeepSearching) ? "" : "h-100vh"
        }`}
      >
        {/* <div className={"help-on-its-way-back-div " + buttonKeyForChatPanel ? "" : "h-100vh"} > */}
        <HeadingWithLogo />
        <div
          className="help-on-its-way-content-div"
          style={(
            buttonKeyForChatPanel
              || showChatPanelForKeepSearching) ? { marginBottom: "15px", marginTop: "15px" }
              : {}
          }
        >
          <div className="help-on-its-way-content">
            <div className="help-on-its-way-upper-div">
              <div className="help-on-its-way-upper-text-div">
                <span className="help-on-its-way-heading">
                  Help is on it’s way
                </span>
                <span className="help-on-its-way-sub-heading">
                  Our best Geeks are getting notified and will be responding within:
                </span>
                <span className="help-on-its-way-timer">
                  <CountDown
                    useTimer={useTimer}
                    renderer={renderer}
                    timesUp={timesUp}
                  />
                </span>
              </div>
              <Player
                autoplay
                keepLastFrame={true}
                src={jsonAnimation}
                className="help-on-its-way-animation"
                loop={true}
              ></Player>
            </div>
            {!buttonKeyForChatPanel && !isKeepSearching && showChatPanelInTimerPage() && (
              <div
                display="flex"
                style={{ marginRight: "-11px" }}
                justifyContent="right"
                marginTop={20}
                marginLeft={10}
                className="float-right invite-tech-btn"
              >
                {!job?.keepSearching && !isKeepSearching && chatPanelHasOpen && showChatPanelInTimerPage() ? (
                  <StyledBadge badgeContent={socketHits} color="secondary">
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        color: "#97abb6",
                        cursor: "pointer",
                        pointerEvents: chatPanelHasOpen ? " " : "none",
                        border: "none",
                        textDecoration: "underline",
                      }}
                      className="bell-icon-class"
                      onClick={handelShowChat}
                    >
                      Chat with technician
                    </span>
                  </StyledBadge>
                ) : (
                  <span></span>
                )}
              </div>
            )}
            {buttonKeyForChatPanel && showChatPanelInTimerPage() && (
              <div
                className="d-flex justify-content-center mb-5"
                style={{ width: "83%" }}
              >
                <ChatPanelTwilio job={job} width={"100%"} height={"500px"} />
              </div>
            )}
                        {!buttonKeyForChatPanel && !showChatPanelForKeepSearching && isKeepSearching &&
                            <div display="flex" style={{ marginRight: '-11px', display: "flex", alignItems: "center" }} justifyContent="right" marginTop={20} marginLeft={10} className="float-right invite-tech-btn">
                                {((job && job?.keepSearching) || isKeepSearching) &&
                                    <StyledBadge badgeContent={socketHits} color="secondary">
                                        <span style={{ display: "flex", justifyContent: "center", fontWeight: "bold", color: "#97abb6", cursor: "pointer", pointerEvents: chatPanelHasOpen ? " " : "none", border: 'none', textDecoration: 'underline' }} className='bell-icon-class' onClick={handelShowChatForKeepSearching}>Trouble finding your technician?</span>
                                    </StyledBadge>
                                }
                            </div>
                        }
                        {!buttonKeyForChatPanel && showChatPanelForKeepSearching && isKeepSearching &&
                            <div className='d-flex justify-content-center mb-5' style={{ width: '83%' }}>
                                <ChatPanelTwilio job={job} width={'100%'} height={'500px'} />
                            </div>
                        }
            <div className="help-on-its-way-lower-div">
              <span className="help-on-its-way-lower-div-heading">
                You'll be notified when your Geek has joined the call by:
              </span>
              <div className="help-on-its-way-lower-div-notification-types">
                <div className="help-on-its-way-lower-div-notification-types-div">
                  <div className="help-on-its-way-lower-div-notification">
                    <div className="d-flex justify-content-center align-items-center position-relative">
                      <ReactSVG src={SCREEN} className="" />
                      <img
                        src={innerScreen}
                        className="position-absolute top-2"
                      />
                    </div>
                    <span className="notification-text">
                      1. A notification on this screen.
                    </span>
                  </div>
                  <div className="help-on-its-way-lower-div-notification">
                    <ReactSVG src={MSG} className="" />
                    <span className="notification-text">
                      2. An email notification.
                    </span>
                  </div>
                  <div className="help-on-its-way-lower-div-notification">
                    <ReactSVG src={PHONE} className="" />
                    <span className="notification-text">
                      {`3. A phone call from `}{" "}
                      {defaultContactNumber.replace(/\(|\)/g, "")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-link-div">
              <Stack spacing={2} direction="row">
                <Button
                  className=""
                  variant="text"
                  onClick={onClickGoToDashbord}
                  // disabled={isButtonDisable}
                >
                  <span className="dashboard-text">Dashboard</span>
                </Button>
              </Stack>
              <span className="search-background-text">
                {" "}
                Search continues in the background
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mrgin-b-10">
            <div className="help-on-its-way-btn-div">
              <span className="help-on-its-way-btn-div-text">
                Don't want to wait
              </span>
              <div className={scheduleforlater ?"help-on-its-way-btn-inner-div-disabled":"help-on-its-way-btn-inner-div"}>
                <div className="d-flex justify-content-center align-items-center fg">
                  <Stack spacing={2} direction="row">
                    <Button
                    disabled={scheduleforlater}
                      className={scheduleforlater ?"sch-btn-text-div-disabled":"sch-btn-text-div"}
                      variant="text"
                      onClick={handleScheduleForLaterClick}
                    >
                      {!isLimitExceed ?( <ReactSVG src={CALANDER} style={{ marginRight: "5px" }} /> ):null}
                     
                      <span className={scheduleforlater ?"sch-btn-text-div-disabled":"sch-btn-text-div"}>
                        Schedule for later
                      </span>
                      
                    </Button>
                    {isLimitExceed ?(
                      <span
                      className="limit-info-round-div ml-1 position-relative"
                      onMouseOver={handleMouseEnter}
                      onMouseOut={handleMouseLeave}
                     
                      
                    >
                     <QuestionMarkOutlinedIcon
                        icon={faInfo}
                        className="icon-of-questionmark"
                      />
                      {isHovering ? (
                        <div className="limitExceedPriceInfo-div">
                          <div className="limitExceedInfo-triangle"></div>
                          <div className="limitExceedPriceInfo-inner-div ">
                            <h6> Maximum Schedule Limit Reached:</h6>
                            <p className="estimate-price">
                             "You can only schedule four jobs at a time. Please wait until a scheduled job is completed or canceled before scheduling a new one."
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </span>
                    ):null}
                    
                  </Stack>
                </div>
                <div className="d-flex justify-content-center align-items-center fg">
                  <Stack spacing={2} direction="row">
                    <Button
                      className=" "
                      variant="text"
                      onClick={handleCancelBtn}
                    >
                      <ReactSVG src={CROSS} style={{ marginRight: "5px" }} />
                      <span className="cancel-btn-text-div cancel-text-div">
                        Cancel search
                      </span>
                    </Button>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={"hide-your-job-label"}>
            <YourJobLabel job={job} buttonKeyForChatPanel={buttonKeyForChatPanel} />
          </div>
          <div className="black-footer"></div>
        </div>
      </div>
      <KeepSearchingModal setIsKeepSearching={setIsKeepSearching}
        showKeepSearchingModal={showKeepSearchingModal}
        setShowKeepSearchingModal={setShowKeepSearchingModal}
        setShowScheduleForLaterModal={setShowScheduleForLaterModal}
        jobInfo={jobInfo}
        setKeepSearchingFor={setKeepSearchingFor}
        keepSearchingFor={keepSearchingFor}
        useTimer={useTimer}
        setUseTimer={setUseTimer}
        job={job}
        setSearchTimesUp={setSearchTimesUp}
        sameTechIdAvailable={sameTechIdAvailable}
        setSameTechIdAvailable={setSameTechIdAvailable}
        sameTechIdAvailableSched={sameTechIdAvailableSched}
        showModalFooterOffPeak={showModalFooterOffPeak}
      />

      <ScheduleForLaterModal
        showScheduleForLaterModal={showScheduleForLaterModal}
        setShowScheduleForLaterModal={setShowScheduleForLaterModal}
        scheduleJobTime={scheduleJobTime}
        setScheduleJobTime={setScheduleJobTime}
        user={user}
        jobInfo={jobInfo}
        job={job}
        isDashboardSide={false}
        setShowKeepSearchingModal={setShowKeepSearchingModal}
        searchTimesUp={searchTimesUp}
      />

      <CancelJobConfirmationModal
        showCancelJobModal={showCancelJobModal}
        setShowCancelJobModal={setShowCancelJobModal}
        job={job}
      />

      <AfterBusinessHrsPopUpModal
        showAfterBusinessHrs={showAfterBusinessHrs}
        setShowAfterBusinessHrs={setShowAfterBusinessHrs}
      />
    </>
  );
};

export default HelpIsOnTheWay;
